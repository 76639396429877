
import React, { useState } from 'react';
import ReactGA from 'react-ga4'
import { Button, Container, Row, Col } from 'react-bootstrap';
import HubspotForm from 'react-hubspot-form'

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    ReactGA.event({
        category: "Click",
        action: "Reveal Contact Form",
        label: "Contact", // optional
    });
    setShow(true);
  }

    const contactForm = !show ?
        <p><Button variant="outline-primary" href="#contact" onClick={() => {handleShow()}}>Contact me</Button></p>
        :
        <HubspotForm 
            portalId= "143473702"
            formId= "edfbf1f0-4d06-4e31-a1ff-daa00a2d8cd9"
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
        />;

    return (
        <>
        <Container id="contact"className="container mt-5">
            <Row>
                <Col md={5}>
                    <h2>Contact</h2>
                    {contactForm}
                </Col>
            </Row>

        </Container>
        <footer className="bg-light">
            <Container>
                <Row>
                    <Col>
                        <ul>
                            {/* <li><a href="https://www.goodreads.com/user/show/170752608-f-winemillercom" target="_blank" rel="noopener">Goodreads</a></li>
                            <li><a href="https://www.youtube.com/@chateau_story" target="_blank" rel="noopener">Youtube</a></li>
                            <li><a href="https://www.tiktok.com/@achateaustory" target="_blank" rel="noopener">TikTok</a></li>
                            <li><a href="https://twitter.com" target="_blank" rel="noopener">Twitter</a></li> */}
                            <li><a href="https://www.instagram.com/achateaustory" target="_blank" rel="noopener">Instagram</a></li>
                            <a href="#contact">Contact</a>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
        </>
    );
}

export default Footer;
