
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const BookReviews = () => {
    return (
        <Container className="container mt-5">
            <Row>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Jost 5/5</Card.Title>
                            <Card.Text>A nice book, entertaining and real. I liked most the parts where the inner dialogue was given with the clear and turbulent emotions in their ups & down</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Arnaud 5/5</Card.Title>
                            <Card.Text>The chateau viewings are transporting, it is like you are living them with Anne & Peter, and discovering cracks & jowels with them! This is a great book for escapism!</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Lucy 4/5</Card.Title>
                            <Card.Text>Easy to read novel following a British couple considering to relocate to France to live in a castle. Scenes are well described as if the reader travelled with the protagonist.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default BookReviews;
