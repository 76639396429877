
import React from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';

import youtubeIcon from '../assets/youtube_icon.svg';
import goodreadsIcon from '../assets/goodreads_icon.svg';
import instagramIcon from '../assets/instagram_icon.svg';
import twitterIcon from '../assets/x_icon.svg';
import tiktokIcon from '../assets/tiktok_icon.svg';
import logo from '../assets/a_chateau_story_192.png';

const Header = () => {
    return (
        <Navbar bg="light" variant="light" sticky="top" className='p-2'>
            <Navbar.Brand href="#home" className='pl-4'>
                <Image src={logo} alt="A Chateau Story" width={48} height={48} className='mr-4' />
                A Chateau Story - a trilogy book series
            </Navbar.Brand>
            <Nav className="ml-auto">
                <Nav.Link href="#books">Books</Nav.Link>
                <Nav.Link href="#author">Author</Nav.Link>
                <Nav.Link href="https://www.instagram.com/achateaustory" target="_blank" rel="noopener"><Image src={instagramIcon} alt="Follow us on Instagram" /></Nav.Link>
                {/* <Nav.Link href="https://www.goodreads.com/user/show/170752608-f-winemiller" target="_blank" rel="noopener"><Image src={goodreadsIcon} alt="Goodreads" /></Nav.Link>
                <Nav.Link href="https://www.youtube.com/@chateau_story" target="_blank" rel="noopener"><Image src={youtubeIcon} alt="Youtube channel" /></Nav.Link>
                <Nav.Link href="hhttps://www.tiktok.com/@achateaustory" target="_blank" rel="noopener"><Image src={tiktokIcon} alt="Follow us on Tiktok" /></Nav.Link>
                <Nav.Link href="https://twitter.com" target="_blank" rel="noopener"><Image src={twitterIcon} alt="Follow us on Twitter" /></Nav.Link> */}
            </Nav>
        </Navbar>
    );
}

export default Header;
