
import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutMe from './components/AboutMe';
import BookReviews from './components/BookReviews';
import Book from './components/Book';
import Footer from './components/Footer';
import './App.css';

function App() {
    useEffect(() => {
        const gaArgs = {
            testMode: process.env.REACT_APP_ENABLE_GA !== "true",
        }
       ReactGA.initialize(process.env.REACT_APP_GA, gaArgs)
    }, []);
    

    return (
        <div className="App">
            <Header />
            <HeroSection />
            <Book />
            <BookReviews />
            <AboutMe />
            <Footer />
        </div>
    );
}

export default App;
