
import React, { useState } from 'react';
import { Button, Container, Row, Col, Image } from 'react-bootstrap';
import ReactGA from 'react-ga4'

import castle from '../assets/book_cover_chateau_story.jpg'; // Import the author’s picture
import HubspotForm from 'react-hubspot-form'

const Book = () => {
    
  const [show, setShow] = useState(false);
  const handleShow = () => {
    ReactGA.event({
        category: "Click",
        action: "Reveal Waitlist Form",
        label: "Join the waitlist", // optional
    });
    setShow(true);
  }

  const preOrderAmazon = () => {
    ReactGA.event({
        category: "Click",
        action: "Pre-order on Amazon",
        label: "Pre-order on Amazon BookComponent", // optional
    });
  }

  const preOrderStripe = () => {
    ReactGA.event({
        category: "Click",
        action: "Pre-order via Stripe",
        label: "Pre-order via Stripe BookComponent", // optional
    });
  }

    const readingBlock = !show ?
    <p><Button variant="primary" href="#waitlist" onClick={() => {handleShow()}}>Join the waitlist</Button></p>
    :
          <HubspotForm 
            portalId= "143473702"
            formId= "da558c40-913e-4fe5-b480-b06f373794a6"
            loading={<div>Loading...</div>}
        />;

    return (
        <Container id="books" className="container mt-5 mb-5 my-5 p-5 bg-light rounded">
            <Row>
                <Col md={8}>
                    <h2>A Chateau Story: <i>The Making of Chatelains</i></h2>
                    <span class="badge badge-warning">Coming soon - Summer 2024</span>
                    <p>After Anne returns from a work project in France, she finds herself with her husband Peter dreaming of a life beyond the hustle of city living. Their quest? To buy a chateau in the French countryside. But as they dive into the world of chateau viewings, they quickly learn that chasing one’s dreams comes with its own set of challenges. From language hurdles to unique property viewings, Anne and Peter navigate the quirks and charms of chateau hunting, turning challenges into the adventure of a lifetime.</p>
                    <p>Anne and Peter’s story offers readers an insider's view into the unique experience of chateau hunting. Full of insights, humour, and a rollercoaster of emotions that accompany the search, "A Chateau Story: The Making of Chatelains" is a light-hearted novel brimming with dreams and the charm of French chateaux. </p>
                    <p>Join Anne and Peter as they lay the foundations of their future, learning that the true essence of home is not just a place, but a journey of the heart.</p>

                </Col>
                <Col md={4}>
                    <Image src={castle} alt="Inspiration of french castle" fluid />
                </Col>
            </Row>
            <Row>
                <Col md={3} />
                <Col md={6} >
                    <br/>
                    <hr />
                    <br/>
                </Col>
                <Col md={3} />
            </Row>
            <Row>
                <Col md={3}></Col>

                <Col md={6}>
                    <h2><center>Get news about A Chateau Story</center></h2>
                    <p><center>
                        By joining our waitlist, you will be the first to receive news and updates on the upcoming book releases.
                        </center>
                    </p>
                    <center>{readingBlock}</center>
                    
                </Col>
                <Col md={3}></Col>
            </Row>
        </Container>
    );
}

export default Book;
