
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import authorImage from '../assets/fe-winemiller.jpg'; // Import the author’s picture

const AboutMe = () => {
    return (
        <Container className="container mt-5"  id="author">
            <Row>
                <Col md={2}>
                    <Image src={authorImage} alt="Author of A Chateau Story" roundedCircle fluid />
                </Col>
                <Col md={10}>
                    <h2>About the author</h2>                
                    <p>
                        Inspired by the timeless beauty of art, travel and the simple joy of indulging in a good meal (chocolate is a must!), Winemiller's writing is a tribute to the sweet things in life. When not typing away on her latest novel, she can be found playing with her poodle, channelling her inner pastry chef, or dabbling in paints, creating scenes that might just inspire her next book. Although Winemiller has yet to add "award-winning" to her author bio, her stories are filled with laughter and a temporary escape from the mundane. 
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutMe;
