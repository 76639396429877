import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { Button } from 'react-bootstrap';
import HubspotForm from 'react-hubspot-form'

const HeroSection = () => {
    // const preOrderAmazon = () => {
    //     ReactGA.event({
    //         category: "Click",
    //         action: "Pre-order on Amazon",
    //         label: "Pre-order on Amazon HeroSection", // optional
    //     });
    // }

    const [show, setShow] = useState(false);
    const handleShow = () => {
      ReactGA.event({
          category: "Click",
          action: "Reveal Waitlist Form",
          label: "Join the waitlist", // optional
      });
      setShow(true);
    }

    const readingBlock = !show ?
    <p><Button variant="outline-primary" href="#waitlist" onClick={() => {handleShow()}}>Join the waitlist!</Button></p>
    :
          <HubspotForm 
            portalId= "143473702"
            formId= "da558c40-913e-4fe5-b480-b06f373794a6"
            loading={<div>Loading...</div>}
        />;

    return (
        <div className="my-5 p-5 bg-light ">
            <h1>Upcoming: <span>"A Chateau Story: <i>The Making of Chatelains</i>"</span></h1>
            <p>Follow Anne & Peter in their journey to become chatelains in France! Subscribe to the waitlist and get updates on the book release.</p>
            {readingBlock}

            <p className="hidden">
                {/* <Button variant="primary" href="#pre-order" onClick={() => {preOrderAmazon()}} className="mr-4">Pre-Order on Amazon Now</Button> */}
            </p>
        </div>
    );
}

export default HeroSection;
